<template>
  <CDataTable
      hover
      striped
      sorter
      index-column
      :items="managers"
      :fields="fieldsManagersITRP"
      :table-filter="{lazy:false, placeholder:'Знайти...', label:' '}"
      :sorter-value="{column:'name', asc:true}"
      :no-items-view="{noResults: 'За результатами пошуку нічого не знайдено', noItems: 'Таблиця пуста ;('}"
  >
    <template #login="data">
      <td>
        <CLink target="_blank"
               :href="`https://dpd.privatbank.ua/search/colleagues?ldap=${data.item.login}`"
        >{{ data.item.login }}</CLink>
      </td>
    </template>
    <template #delete="data">
      <td>
        <CButton
            color="danger"
            size="sm"
            variant="outline"
            @click="deleteManager(data.item.id)">Видалити
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>
<script>
import {fieldsManagersITRP} from "~/Components/Grid/Fields";

export default {
  props: {
    managers: {
      type: Array,
      default: undefined,
      required: true
    }
  },
  emits: ['deleteManager'],
  data() {
    return {
      fieldsManagersITRP,
    }
  },
  methods: {
    deleteManager(id) {
      console.log(id)
      this.$emit('deleteManager', id)
    }
  }
}
</script>