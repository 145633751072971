import axios from "axios";

const API_Get_Managers = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/staff/chiefs/detailed')
            .then(resp => resp.data)
            .then(resp => resolve(resp))
            .catch(err => reject(err.response.data))
    })
}

export const getManagers_it_rp = () => {
    return new Promise((resolve, reject) => {
        API_Get_Managers()
            .then(resp => resolve(resp))
            .catch(err => reject(err));
    })
}


const API_Delete_Manager = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/staff/chiefs/${id}`)
            .then(resp => resp.data)
            .then(resp => resolve(resp))
            .catch(err => reject(err.response.data))
    })
}

export const deleteManager_it_rp = (id) => {
    return new Promise((resolve, reject) => {

        API_Delete_Manager(id)
            .then(resp => resolve(resp))
            .catch(err => reject(err))
    })
}

const API_Add_Manager_IT = async (data) => {
    return await new Promise((resolve, reject) => {
        axios.post('/api/staff/chiefs', {...data})
            .then(resp => resp.data)
            .then(resp => resolve(resp))
            .catch(err => reject(err.response.data))
    })
}


export const saveNewManager_it_rp = (manager) => {
    const managerValidate = ["login", "name"];
    const {name, login} = manager;

    managerValidate.forEach(key => {
        if (!manager[key]) return Promise.reject(`Відсутні заповнення полів ${managerValidate}`)
    });

    const promiseSaveManagerIT = manager.mnm_arr.map(item => {
        return new Promise(async (resolve, reject) => {

            await API_Add_Manager_IT({...item, name, login})
                .then(resp => resolve())
                .catch(err => reject(err));
        });
    })

    return Promise.all(promiseSaveManagerIT)
        .then(() => Promise.resolve("Усі менеджери успішно збережені"))
        .catch((err) => Promise.reject("Помилка збереження:", err));
}

const API_GET_NAME_FROM_LOGIN = (login) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/staff/chiefs/${login}/name`)
            .then(resp => resp.data)
            .then(resp => resolve(resp))
            .catch(err => reject(err.response.data));
    })
}

export const getNameManagerIT = (login) => {
    return new Promise((resolve, reject) => {
        API_GET_NAME_FROM_LOGIN(login)
            .then(resp => resolve(resp))
            .catch(err => reject(err));
    })
}