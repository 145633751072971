<template>
  <CModal
      title="Додати керівниа ІТ РП"
      :show.sync="showModal"
      size="lg"
      color="success"
      addContentClasses="srl"
  >
    <template #body-wrapper>
      <div class="px-4 py-2">
        <ul v-if="error.length >= 1" style="list-style: none" class="p-0">
          <li v-for="err of error" :key="error.indexOf(err)">
            <CAlert style="font-size: 12px;" class="text-left" show :color="err.color">{{ err.message }}</CAlert>
          </li>
        </ul>

        <div class="form-tem w-100" :class="{'errorInput': $v.login.$error}">
          <CInput
              label="Логін керівника ІТ"
              @input="checkManager"
              @change="$v.login.$touch()"
              placeholder="Логін керівника ІТ"
              v-model="login"
              :maxLength="$v.login.$params.maxLength.max"
          />
          <small v-if="!$v.login.$params.required || !$v.login.$params.maxLength || !$v.login.$params.minLength"
                 class="form-text errorText w-100 ml-3">Необхідно заповнити Логін керівника ІТ.</small>
        </div>

        <div class="form-item w-100" :class="{'errorInput': $v.name.$error}">
          <CInput disabled placeholder="П.І.Б керівника ІТ" v-model="name">
            <template #label>
              <label>П.І.Б керівника ІТ
                <CSpinner v-if="name_spinner" color="success" size="sm"/>
              </label>
            </template>
          </CInput>
          <small v-if="!$v.name.$params.required || !$v.name.$params.maxLength || !$v.name.$params.minLength"
                 class="form-text errorText w-100 ml-3">Необхідно заповнити П.І.Б керівника ІТ.</small>
        </div>


        <div class="px-2 py-1 my-2 border-1 border-success rounded borderBottomDiv">
          <CRow class="mx-1 py-2 borderBottom justify-content-between" v-for="(item, index) in block_mnm_arr">
            <div
                class="form-item flex-grow-1 px-0"
                :class="{'errorInput': $v.block_mnm_arr.$each[index].mrm.$error}"
            >
              <CInput
                  label="МРУ"
                  placeholder="МРУ"
                  v-model="item.mrm"
                  :maxLength="$v.block_mnm_arr.$each[index].mrm.$params.maxLength.max"
                  @change="$v.block_mnm_arr.$each[index].mrm.$touch()"
                  class="px-1 m-0"/>

              <small v-if="!$v.block_mnm_arr.$each[index].mrm.$params.required ||
              !$v.block_mnm_arr.$each[index].mrm.$params.maxLength ||
              !$v.block_mnm_arr.$each[index].mrm.$params.minLength" class="form-text errorText w-100 ml-3">Необхідно
                заповнити МРУ.</small>
            </div>
            <div
                class="form-item flex-grow-1 px-0"
                :class="{'errorInput': $v.block_mnm_arr.$each[index].branch_mnemonic.$error}"
            >
              <CInput
                  label="Бранч"
                  placeholder="Бранч"
                  :maxLength="$v.block_mnm_arr.$each[index].branch_mnemonic.$params.maxLength.max"
                  @change="$v.block_mnm_arr.$each[index].branch_mnemonic.$touch()"
                  v-model="item.branch_mnemonic"
                  class="px-1 m-0"/>

              <small v-if="!$v.block_mnm_arr.$each[index].branch_mnemonic.$params.required ||
              !$v.block_mnm_arr.$each[index].branch_mnemonic.$params.maxLength ||
              !$v.block_mnm_arr.$each[index].branch_mnemonic.$params.minLength" class="form-text errorText w-100 ml-3">Необхідно
                заповнити Бранч.</small>
            </div>
            <div v-if="block_mnm_arr.length > 1" class="align-self-end">
              <CButton color="danger" variant="outline" size="sm"
                       @click="removeItemMRM(index)"
                       class="py-2"
              >Видалити
              </CButton>
            </div>

          </CRow>


          <CRow class="px-3 py-1 mt-3 justify-content-end">
            <CButton color="success" size="sm" variant="ghost" @click="addMRM">Додати</CButton>
          </CRow>
        </div>


      </div>
    </template>
    <template #footer>
      <CButton size="sm" color="secondary" class="m-1" @click="closed">Скасувати</CButton>
      <CButton :disabled="!login && !name" size="sm" color="success" class="m-1" @click="validate">Додати</CButton>
    </template>
  </CModal>
</template>
<script>
import {maxLength, minLength, required, requiredIf} from "vuelidate/lib/validators";
import {getNameManagerIT} from "@/api/editManagersITRP";

export default {
  props: {
    showModal: {required: true, default: false, type: Boolean},
    saveManager: {required, type: Function, default: undefined}
  },
  data() {
    return {
      name: '',
      login: '',
      block_mnm_arr: [
        {
          mrm: '',
          branch_mnemonic: ''
        }
      ],
      block_mnm: {
        mrm: '',
        branch_mnemonic: ''
      },
      name_spinner: false,
      error: []
    }
  },
  validations: {
    login: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(13)
    },
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(150)
    },
    block_mnm_arr: {
      $each: {
        mrm: {
          required: requiredIf(v => !v.mrm && !v.branch_mnemonic),
          type: String,
          minLength: minLength(4),
          maxLength: maxLength(4)
        },
        branch_mnemonic: {
          required: requiredIf(v => !v.mrm && !v.branch_mnemonic),
          type: String,
          minLength: minLength(2),
          maxLength: maxLength(2)
        }
      }
    }
  },
  methods: {
    addMRM() {
      this.block_mnm_arr.push({...this.block_mnm});
    },
    validate() {
      this.error = [];
      this.$v.$touch()
      if (this.$v.$invalid) return;
      this.save();

    },
    save() {
      let data = {
        name: this.name,
        login: this.login,
        mnm_arr: this.block_mnm_arr
      }
      this.$props.saveManager(data)
          .then(resp => {
            console.log(resp)
            this.showMessage(resp, 'success', true);
          })
          .catch(err => this.showMessage(err, 'danger'))
    },
    showMessage(message, color, close = null) {
      if (this.error.includes(message)) return;

      this.error.push({color, message});

      if (close) return setTimeout(() => {
        this.$emit('updated');
        this.closed();
      }, 3000);

      //  setTimeout(() => this.error = [], 3000);
    },
    checkManager() {
      this.error = [];
      const {minLength, maxLength} = this.$v.login.$params;
      const isValidLength = this.login.length >= minLength.min && this.login.length <= maxLength.max;

      if (isValidLength) {
        this.name_spinner = true;
        getNameManagerIT(this.login)
            .then(resp => {
              this.name = resp;
              this.name_spinner = false;
            })
            .catch(err => {
              this.name_spinner = false;
              this.showMessage(`Помилка при знаходженні імені`, 'danger');
            })
      }
    },
    clearing() {
      this.mrm = '';
      this.name = '';
      this.login = '';
      this.block_mnm_arr = [{...this.block_mnm}];
      this.error = [];
      this.$v.$reset();
    },
    removeItemMRM(index) {
      this.block_mnm_arr.splice(index, 1);
    },
    closed() {
      this.clearing();
      this.$emit('closing')
    },
  },
  watch: {
    showModal(val) {
      this.clearing();
      if (!val) this.closed();
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: inline-block;
  }

  &label {
    margin: 0;
    padding: 0 5px;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}

.borderBottom {
  border-bottom: 1px dashed #468d46;
}
</style>
