<template>
  <CRow class="w-100 justify-content-center">
    <CCol>
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between px-2">
            <h3>Редагування керівників ІТ</h3>
            <CButton color="success" variant="outline" @click="showModalManagerIT(true)">Додати +</CButton>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <managers-i-t-r-p-grid :managers="managers" @deleteManager="deleteManager"/>
        </CCardBody>
      </CCard>
    </CCol>
    <modal-add-manager-i-t-r-p
        :show-modal="showModalManagerITRP"
        @closing="showModalManagerIT(false)"
        @updated="getManagers"
        :save-manager="saveNewManager_it_rp"/>

    <modal-any-information
        color="danger"
        title="Видалити керівника"
        @close="onCloseDeleteManager"
        :show-any-information-modal="confirmDeleteManagerModal">
      <template v-slot:body>
        <CCardBody class="p-1">
          <h2>Ви підтверджуєте видалення керівника ІТ?</h2>
        </CCardBody>
      </template>
      <template v-slot:footer>
        <CButton
            ref="cancelDeleteManagerIT"
            color="secondary"
        >Скасувати
        </CButton>
        <CButton
            ref="confirmDeleteManagerIT"
            color="danger">
          Видалити
        </CButton>
      </template>
    </modal-any-information>
    <modal-any-information
        color="danger"
        title="Помилка при видалені керівника"
        @close="showModalErrorDeleteManager(false)"
        :show-any-information-modal="showModalErrorDelete">
      <template v-slot:body>
        <CCardBody class="p-1">
          <h2>Сталась помилка при видалені керівника: {{ errMessage }}</h2>
        </CCardBody>
      </template>
      <template v-slot:footer>
        <CButton
            @click="showModalErrorDeleteManager(false)"
            color="danger"
        >
          Закрити
        </CButton>
      </template>
    </modal-any-information>
  </CRow>
</template>
<script>
import ManagersITRP from "~/Components/Grid/ManagersITRPGrid.vue";
import ManagersITRPGrid from "~/Components/Grid/ManagersITRPGrid.vue";
import {deleteManager_it_rp, getManagers_it_rp, saveNewManager_it_rp} from "@/api/editManagersITRP";
import ModalAddManagerITRP from "~/Components/Modal/ModalAddManagerITRP.vue";
import ModalAnyInformation from "~/Components/Modal/ModalAnyInformation.vue";

export default {
  name: 'BankEditManagerITRP',
  components: {ModalAnyInformation, ModalAddManagerITRP, ManagersITRPGrid, ManagersITRP},
  data() {
    return {
      managers: undefined,
      showModalManagerITRP: false,
      confirmDeleteManagerModal: false,
      showModalErrorDelete: false,
      errMessage: null
    }
  },
  created() {
    this.getManagers();
  },
  methods: {
    saveNewManager_it_rp,
    getManagers() {
      getManagers_it_rp()
          .then(resp => {
            this.managers = [...resp]
          });
    },
    onCloseDeleteManager() {
      this.$refs.cancelDeleteManagerIT.click();
    },
    confirmDeleteManager() {
      return new Promise((resolve, reject) => {
        this.confirmDeleteManagerModal = true;

        const confirmClick = () => {
          this.confirmDeleteManagerModal = false;
          cleanup();
          resolve("confirm");
        };

        const cancelClick = () => {
          this.confirmDeleteManagerModal = false;
          cleanup();
          reject("cancel");
        };
        const cleanup = () => {
          this.$refs.confirmDeleteManagerIT.removeEventListener('click', confirmClick);
          this.$refs.cancelDeleteManagerIT.removeEventListener('click', cancelClick);
        };

        this.$nextTick(() => {
          this.$refs.confirmDeleteManagerIT.addEventListener('click', confirmClick);
          this.$refs.cancelDeleteManagerIT.addEventListener('click', cancelClick);
        });
      });
    },
    async deleteManager(id) {
      const deleteManager = await this.confirmDeleteManager();
      if (deleteManager === 'confirm') {
        deleteManager_it_rp(id)
            .then(() => this.getManagers())
            .catch(err => this.showModalErrorDeleteManager(true, err))
      }
    },
    showModalManagerIT(show) {
      this.showModalManagerITRP = show;
    },
    showModalErrorDeleteManager(show, message = null) {
      this.showModalErrorDelete = show;
      if (!show) this.errMessage = null;
      this.errMessage = message;
    }
  }
}
</script>